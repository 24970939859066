(function($){

    function addZ(n){
        return n<10? '0'+n:''+n;
    }

    $.fn.serializeObject = function()
    {
        var o = {};
        var a = this.serializeArray();
        $.each(a, function() {
            if (o[this.name] !== undefined) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }
        });
        return o;
    };

    $(document).ready(function() {

        //// bars
        // var dp = $('#inline-datepicker , #inline-datepicker_placeholder');
        var dp = $('#inline-datepicker ');
        var submit_buttons = '#booking_form_box #bf_submit , a#mobile_open_booking';
        var today = new Date();

        // update the hidden inputs
        function update_inputs(arrival_date, departure_date ,num_of_nights ) {
            // vars
            var fromday = 0;
            var frommonth = 0;
            var fromyear = 0;
            var today = 0;
            var tomonth = 0;
            var toyear = 0;
            if( typeof num_of_nights !== 'undefined') {
                // VERTICAL BOOKING
                $('input[name=notti_1]').val(num_of_nights);
            }
            if( typeof arrival_date !== 'undefined' && arrival_date!=false) {
                fromday = ((new Date(arrival_date)).getDate() < 10 ? '0' : '') + (new Date(arrival_date)).getDate();
                frommonth = ((new Date(arrival_date)).getMonth() < 9 ? '0' : '') + ((new Date(arrival_date)).getMonth() + 1);
                fromyear = (new Date(arrival_date)).getFullYear();
            }
            if( typeof departure_date !== 'undefined' && departure_date!=false) {
                today = ((new Date(departure_date)).getDate() < 10 ? '0' : '') + (new Date(departure_date)).getDate();
                tomonth = ((new Date(departure_date)).getMonth() < 9 ? '0' : '') + ((new Date(departure_date)).getMonth() + 1);
                toyear = (new Date(departure_date)).getFullYear();
            }

            // VERTICAL BOOKING
            // i update the hidden fields for the QS form
            // checkin
            $('input[name=gg]').val(fromday);
            $('input[name=mm]').val(frommonth);
            $('input[name=aa]').val(fromyear);
            // checkout
            $('input[name=ggf]').val(today);
            $('input[name=mmf]').val(tomonth);
            $('input[name=aaf]').val(toyear);

            // FASTBOOKING
            // i update the hidden fields for the QS form
            $('input[name=fromday]').val(fromday);
            $('input[name=frommonth]').val(frommonth);
            $('input[name=fromyear]').val(fromyear);

            // i update the hidden fields for departure date
            $('input[name=today]').val(today);
            $('input[name=tomonth]').val(tomonth);
            $('input[name=toyear]').val(toyear);
            // slope booking
            $('input[name="reservation[stay][arrival]"]').val(fromday + '/' + frommonth + '/' + fromyear);
            $('input[name="reservation[stay][departure]"]').val(today + '/' + tomonth + '/' + toyear);
        }

        function get_arrival_date(){
            var fromday = jQuery('input[name=fromday]').val();
            var frommonth = jQuery('input[name=frommonth]').val();
            var fromyear = jQuery('input[name=fromyear]').val();
            return new Date( fromyear, parseInt(frommonth)-1, fromday, 0, 0, 0, 0 );
        }

        function get_departure_date(){
            var today = jQuery('input[name=today]').val();
            var tomonth = jQuery('input[name=tomonth]').val();
            var toyear = jQuery('input[name=toyear]').val();
            return new Date( toyear, parseInt(tomonth)-1, today, 0, 0, 0, 0 );
        }

        function build_datepicker_period( dateText, inst ) {

            // console.log(dateText);
            // console.log(inst);

            var form = jQuery('#booking_form_container');
            var arrival_date;
            var departure_date;
            var num_of_nights;
            var returnedData = {};
            prv = cur;
            cur = (new Date(inst.selectedYear, inst.selectedMonth, inst.selectedDay)).getTime();
            if ( prv == -1 || prv == cur ) {
                form.addClass('on-selection').removeClass('selection-finished');
                prv = cur;
                var selected_date = new Date(inst.selectedYear, inst.selectedMonth, inst.selectedDay);
                var tomorrow = new Date(selected_date.getTime()+86400000);
                arrival_date = $.datepicker.formatDate( _date_format, selected_date, {} );
                // departure_date = $.datepicker.formatDate( _date_format, tomorrow, {} );
                // i put the selected date on arrival
                $('.date_arrival .arrival, #booking_form_placeholder .dates .date.from').text( arrival_date );
                // $('.date_departure .departure, #booking_form_placeholder .dates .date.to').text( departure_date );
                $('.date_departure .departure, #booking_form_placeholder .dates .date.to').text( '...' );
                // update_inputs( selected_date.getTime() , tomorrow.getTime() );
                update_inputs( selected_date.getTime() , false ,0 );
                $('.box_nights .n_nights'  ).text('...');
                // sync the 2 datepickerz for date and months
                if(inst.id=='inline-datepicker_placeholder'){
                    selector = $('#inline-datepicker');
                }
                if(inst.id=='inline-datepicker'){
                    selector = $('#inline-datepicker_placeholder');
                }
                // setting date
                selector.datepicker( "setDate", tomorrow);
                // disable submit
                $('#bf_submit').prop('disabled', true).addClass('disabled');

            } else {
                form.removeClass('on-selection').addClass('selection-finished');
                arrival_date = $.datepicker.formatDate( _date_format, new Date(Math.min(prv,cur)), {} );
                departure_date = $.datepicker.formatDate( _date_format, new Date(Math.max(prv,cur)), {} );
                // number of nights
                num_of_nights = Math.abs(Math.round(( cur - prv ) / (24 * 60 * 60 * 1000)));
                // i update the fields
                $('.date_arrival .arrival, #booking_form_placeholder .dates .date.from'  ).text( arrival_date );
                $('.date_departure .departure, #booking_form_placeholder .dates .date.to'  ).text( departure_date );
                $('.box_nights .n_nights'  ).text( num_of_nights );
                // update the hidden inputs
                update_inputs( Math.min(prv,cur) , Math.max(prv,cur) ,num_of_nights );
                // reset!
                prv = -1; cur = -1;
                // sync the 2 datepickerz for date and months
                if(inst.id=='inline-datepicker_placeholder'){
                    selector = $('#inline-datepicker');
                }
                if(inst.id=='inline-datepicker'){
                    selector = $('#inline-datepicker_placeholder');
                }

                // try to sync the 2 calendars
                selector.datepicker( "setDate", new Date(Math.min(prv,cur)));

                // enable submit
                $('#bf_submit').prop('disabled', false).removeClass('disabled');
            }
        }


        function init_datepickers(arrival_date, departure_date) {
            // vars
            var today = new Date();
            var tomorrow = new Date(today.getTime()+86400000);
            if(typeof(hotel_data.hotel_opendate)!=='undefined' && hotel_data.hotel_opendate!='') {
                var opendate_pcs = hotel_data.hotel_opendate.split("/");
                var opendate = new Date(opendate_pcs[2], opendate_pcs[0] - 1, opendate_pcs[1]);
                if(opendate.getTime()>today.getTime()) {
                    today = opendate;
                }
            }


            // initialize inputs
            update_inputs( today.getTime() , tomorrow.getTime() );
            window._date_format = 'dd MM yy';

            window.cur = -1;
            window.prv = -1;

            // inin the datepicker
            dp.datepicker({
                numberOfMonths: 2,
                'minDate': today,
                firstDay: 0,
                beforeShowDay: function ( date ) {
                    var _class = '';
                    var _arrival_date = get_arrival_date().getTime();
                    var _departure_date = get_departure_date().getTime();
                    if(date.getTime() == _arrival_date ) {
                        _class = 'date-range-selected checkin-date';
                    } else if(date.getTime() == _departure_date ) {
                        _class = 'date-range-selected checkout-date';
                    } else if(date.getTime() > _arrival_date && date.getTime() < _departure_date ) {
                        _class = 'date-range-selected';
                    } else {
                        _class = '';
                    }

                    return [true, _class];
                },
                onSelect: build_datepicker_period
            });
            //
        }

        // openclose BF BOX
        function open_close_bf_box(close,opts){
            // debug(opts);
            // close?
            if(typeof close === 'undefined') close = false;
            // vars
            var booking_form_wrap = $('#booking_form_wrap');
            var booking_form_placeholder = $('#booking_form_placeholder');
            var	mClass = ('opened');
            var noScrollClass = ('noscroll');
            // action
            if (!booking_form_wrap.hasClass(mClass) && !close ) {
                // on mobile!!!
                if(window.is_mobile) {
                    booking_form_wrap.find('#bf_submit').trigger('click');
                } else {
                    // open
                    $('html').addClass(noScrollClass);
                    // first step - i put it above all
                    booking_form_wrap.addClass('block');
                    setTimeout(function () {
                        // second step - fade in
                        booking_form_wrap.addClass(mClass);
                    }, 200);


                    var hotels = booking_form_wrap.find('select.hotel_bf_list option');

                    // select the hotel that is selected in the placeholder
                    var sel_hotel = booking_form_placeholder.find('select.hotel_bf_list').val();
                    hotels.each(function(){
                        var hotel_code = $(this).val();
                        if(sel_hotel==hotel_code) {
                            $(this).prop('selected', true);
                        }
                    });

                    // select hotel if passed
                    if(typeof opts != 'undefined' && typeof opts.sel_hotel != 'undefined' ) {
                        hotels.each(function(){
                            var hotel_code = $(this).val();
                            if(opts.sel_hotel==hotel_code) {
                                $(this).prop('selected', true);
                            }
                        });
                    }

                    // promo code if passed
                    if(typeof opts != 'undefined' && typeof opts.promo_code != 'undefined' ) {
                        booking_form_wrap.find('.promo_code input').val(opts.promo_code);
                    }

                    // dates opt
                    if(typeof opts != 'undefined' && typeof opts.dates != 'undefined' ) {

                        // datepicker instance
                        var id = document.getElementById('inline-datepicker');
                        var inst = window.$.datepicker._getInst(id);

                        // if dates are not passed
                        if( typeof opts.dates.date_start !== 'undefined') {
                            var arrival_date_pcs = opts.dates.date_start.split("-");
                            var start_obj = new Date(arrival_date_pcs[0], (parseInt(arrival_date_pcs[1],10)-1), arrival_date_pcs[2]);
                            var start = arrival_date_pcs[2]+'/'+(arrival_date_pcs[1])+'/'+arrival_date_pcs[0];
                            $('#inline-datepicker').datepicker( "setDate", start_obj);
                            build_datepicker_period( start, inst );
                            if( typeof opts.dates.date_end !== 'undefined') {
                                var departure_date_pcs = opts.dates.date_end.split("-");
                                var end_obj = new Date(departure_date_pcs[0], parseInt(departure_date_pcs[1],10)-1 , departure_date_pcs[2]);
                                var end = departure_date_pcs[2]+'/'+(departure_date_pcs[1])+'/'+departure_date_pcs[0];
                                $('#inline-datepicker').datepicker( "setDate", end_obj);
                                build_datepicker_period( end, inst );
                            }
                            $('#inline-datepicker').datepicker( "setDate", start_obj);
                        }

                    }


                }
            } else {
                if(booking_form_wrap.hasClass(mClass)){
                    // close
                    // first step - fade out
                    booking_form_wrap.removeClass(mClass);
                    setTimeout(function () {
                        // second step - i put all below
                        booking_form_wrap.removeClass('block');
                    }, 500);
                    $('html').removeClass(noScrollClass);
                }
            }
        }
        // open / close  bf box
        // box
        var booking_form_wrap = $('#booking_form_wrap');
        // btns
        var btns_open = $('#open_booking_placeholder a ,#header #open_booking a');
        var	btn_close = $('#close_booking');
        // triggers buttons
        btns_open.on('click',function () {
            open_close_bf_box();
        });
        btn_close.on('click',function () {
            open_close_bf_box();
        });
        $(document).on('click',function (event) {
            if (  !$(event.target).closest('#booking_form_box').length
                && !$(event.target).closest('.ui-datepicker-group').length
                && !$(event.target).closest('#booking_form_placeholder').length
                && !$(event.target).closest('#header').length
                && !$(event.target).closest('.map_marker_popup').length
                && !$(event.target).closest('.book_btn.open_single').length
                && !$(event.target).closest('.go_iata a').length
            ) {
                open_close_bf_box(true);
            }
        });
        $(document).on('click','.map_marker_popup .content a.book',function (event) {
            var hotel_code = $(this).data('cname');
            var opts = {sel_hotel:hotel_code};
            open_close_bf_box(false,opts);
        });

        //START ME UP
        init_datepickers();

        // book now button
        $(document).on('click',submit_buttons,function() {
            var btn = jQuery(this);
            if(btn.hasClass('fastbooking')) {
                hhotelDispoprice(document.idForm);
            }
            if(btn.hasClass('vertical')) {
                open_vb_be();
            }
            });

        // vertical booking general book function
        function open_vb_be() {

            // form info
            var data = $('#idForm').serializeObject();
            // hotel booking
            var booking_url = data.booking_url;
            var results_url = data.results_url;
            // params not to be passed to the be
            delete data.booking_url;
            delete data.results_url;
            delete data.cancel_mod_url;
            delete data.fromday;
            delete data.frommonth;
            delete data.fromyear;
            delete data.today;
            delete data.tomonth;
            delete data.toyear;
            //
            var str = jQuery.param( data );
            var burl = booking_url+"?"+str;
            // console.log(data);
            console.log(data);
            //
            window.open(burl, "_blank");
        }

        // cancel function
        $('a.cancel_booking').on('click tap',function(){
            var data = $('#idForm').serializeObject();
            //url
            var cancel_modify_url = data.cancel_mod_url;

            // params not to be passed to the be
            delete data.results_url;
            delete data.cancel_mod_url;
            delete data.booking_url;
            delete data.fromday;
            delete data.frommonth;
            delete data.fromyear;
            delete data.today;
            delete data.tomonth;
            delete data.toyear;

            // building the query string and the url
            var str = jQuery.param( data );
            var burl = cancel_modify_url+"?"+str;

            // open the url
            window.open(burl, "_blank");
        });


        // selects
        $('.select-wrapper').each(function(){
            var sel_wrap = $(this);
            var sel_input = sel_wrap.data('inputname');
            var sel_label = sel_wrap.find('.select-label');
            var sel_dbox = sel_wrap.find('.select-dropdown-box');
            var sel_d = sel_wrap.find('.select-dropdown');
            var sel_opts = sel_wrap.find('.select-option');
            sel_label.on('click tap',function(){
                if(sel_dbox.hasClass('opened')) {
                    sel_dbox.animate({
                        height: 0
                    },300, function() {
                    });
                    sel_dbox.removeClass('opened');
                    sel_wrap.removeClass('opened');
                    return;
                }
                var h = sel_d.outerHeight();
                sel_dbox.animate({
                    height: h
                },300, function() {
                    sel_dbox.addClass('opened');
                    sel_wrap.addClass('opened');
                });
            });
            sel_opts.each(function(){
                $(this).on('click',function(){
                    var val = $(this).data('value');
                    sel_label.html(val);
                    sel_dbox.animate({
                        height: 0
                    },300, function() {
                        //slope booking
                        if(hotel_data.booking_engine == 3){
                            if(sel_input == 'reservation[guests][adults]'){
                                $('input[name="reservation[guests][adults]"]').val(val);
                            } else {
                                $('input[name="reservation[guests][children]"]').val(val);
                            }
                        } else {
                            $('input[name=' + sel_input + ']').val(val);
                        }
                        sel_dbox.removeClass('opened');
                    });
                });
            });
        });

        // placeholder
        $('#booking_form_placeholder .dates').on('click',function(){
            // $('#inline-datepicker-box_placeholder').addClass('visible');
            open_close_bf_box();
        });
        $(document).on('click',function (event) {
            var placeholder_calendars = $('#inline-datepicker-box_placeholder');
            if (  placeholder_calendars.hasClass('visible')
                && !$(event.target).closest('#inline-datepicker-box_placeholder').length
                && !$(event.target).closest('#booking_form_placeholder .dates').length
                && !$(event.target).closest('#booking_form_placeholder .dates').length
                && !$(event.target).closest('.ui-datepicker-group').length
            ) {
                // let's close it
                placeholder_calendars.removeClass('visible');
            }

        });

        // book btn vertical
        $('.book_btn').on('click',function(){
            // MOBILE
            if(window.is_mobile) {
                // wrap
                var booking_form_wrap = $('#booking_form_wrap');
                // opts of the button
                var opts = $(this).data('opts');
                // hotels selector
                var hotels = booking_form_wrap.find('select.hotel_bf_list option');
                // select hotel if passed
                if(typeof opts != 'undefined' && typeof opts.sel_hotel != 'undefined' ) {
                    hotels.each(function(){
                        var hotel_code = $(this).val();
                        if(opts.sel_hotel==hotel_code) {
                            $(this).prop('selected', true);
                        }
                    });
                }
                // promo code if passed
                if(typeof opts != 'undefined' && typeof opts.promo_code != 'undefined' ) {
                    booking_form_wrap.find('.promo_code input').val(opts.promo_code);
                }
                booking_form_wrap.find('#bf_submit').trigger('click');
                return;
            }

            if($(this).hasClass('open_single')) {
                var opts = $(this).data('opts');
                open_close_bf_box(false,opts);
                return;
            }
            // obj
            var data = {};
            // offer id
            var offerid = $(this).data('offer_id');
            if(typeof hotel_data == 'undefined' || offerid == '' ) return false;
            // putting data
            data.id_prodotto_sel = offerid;
            data.id_stile = hotel_data.hotel_id_style;
            data.id_albergo = hotel_data.hotel_hid;
            data.dc = hotel_data.hotel_dc;
            data.lingua_int = $('input[name=lingua_int]').val();
            // creating the url params
            var str = jQuery.param( data );
            // vars
            var offers_booking_url = hotel_data.offers_url;
            var offer_url = offers_booking_url+"?"+str;

            //
            window.open(offer_url, "_blank");
        });


        // offers section
        // fancybox
        $('.offer a.title, .offer a.info , .offer a.angle_more , .offer .thumb a , .offer a.thumb_box').fancybox({
            padding: 0,
            wrapCSS: 'single_offer_wrap',
            closeBtn: false
        });
        $('.close_offer').on('click',function(){
            $.fancybox.close();
        });


    }); // END DOM READY

})(jQuery);

